@import "~antd/dist/antd.css";

.layout {
  background-color: white;
}

.content {
  background-color: white;
}

.site-page-header-ghost-wrapper {
  padding: 0px;
  background-color: white;
}

.footer {
  background-color: #f3f3f3;
}

.ant-card-meta-title {
  white-space: pre-line;
}

.ant-card-head {
  margin: 0 0 0 0;
}

.ant-card-head-title {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: 44px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.ant-tabs-tab {
  margin-left: 20px;
}

.ant-back-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: fixed;
  right: 10px;
  bottom: 40px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.chart-card {
  border-bottom: 1px solid #f0f0f0;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  background: #fff;
  border-radius: 0px;
}

.ant-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.ant-card-body {
  padding: 24px;
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  font-size: 12px;
  border-top: 0px;
  /* border-left: 0px;
  border-right: 0px; */
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 10px 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab:not(:last-of-type) {
  margin-right: -1px;
}

.ant-layout-header {
  height: 74px;
  padding: 0 0px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 74px;
  background: #fff;
}

.ant-list-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
/* 
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
} */

.ant-list-header {
  background: #fafafa;
}
